<script>
import Navigation from "@/components/Navigation.vue";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
    name: "TestPage",
    mounted() {
        document.title = `Testing | ${this.$t("title")}`;
    },
    components: {
        "my-nav": Navigation,
        "lang-switcher": LanguageSwitcher
    }
}
</script>

<template>
    <!-- <my-nav /> -->
    <lang-switcher />
</template>

<style lang="scss">

</style>