<template>
    <div class="language-switcher">
        <select v-model="language" value="language" :onchange="setLocale" name="language" id="language">
            <option selected :value="$router.currentRoute.value.params.lang">
                {{ $t($router.currentRoute.value.params.lang) }}
            </option>
            <option :value="getOtherLang($router.currentRoute.value.params.lang)">
                {{ $t(getOtherLang($router.currentRoute.value.params.lang)) }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isVisible: false,
            language: "de",
        }
    },
    methods: {
        setLocale() {
            setTimeout(() => {
                this.$router.push({
                    params: { lang: this.language }
                });
            }, 100);
        },
        getOtherLang(lang) {
            if (lang == "de") return "en";
            return "de";
        }
    }
}
</script>

<style lang="scss">
.language-switcher {
    position: relative;

    button {
        display: flex;
        background: transparent;
        border: none;
        cursor: pointer;
        align-items: center;

    }

    .langs {
        position: absolute;
        background-color: white;
        overflow: hidden;
        border-radius: 0px 0px 10px 10px;

        .lang-link {
            cursor: pointer;
            display: flex;
            color: black;

            &:hover {
                background-color: rgb(180, 180, 180);
            }
        }
    }
}
</style>