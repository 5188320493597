import { createRouter, createWebHistory } from "vue-router";
import Home from "./views/HomePage.vue";
import Contact from "./views/ContactPage.vue";
import Gallery from "./views/GalleryPage.vue";
import Image from "./views/ImagePage.vue";
import Test from "./views/TestPage.vue";

const routes = [
  {
    path: "/",
    redirect: "/de/home",
  },
  {
    path: "/de",
    redirect: "/de/home",
  },
  {
    path: "/en",
    redirect: "/en/home",
  },

  {
    path: "/home",
    redirect: "/de/home",
  },
  {
    path: "/contact",
    redirect: "/de/contact",
  },
  {
    path: "/gallery",
    redirect: "/de/gallery",
  },
  {
    path: "/test",
    redirect: "/de/test",
  },
  {
    path: "/:wrongurl",
    redirect: "/de/home",
  },

  {
    path: "/:lang",
    children: [
      {
        path: "home",
        name: "home",
        component: Home,
      },
      {
        path: "contact",
        name: "contact",
        component: Contact,
      },
      {
        path: "gallery",
        children: [
          {
            path: "",
            name: "gallery",
            component: Gallery,
          },
          {
            path: ":imagename",
            component: Image,
          },
        ],
      },
      {
        path: "test",
        name: "test",
        component: Test,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash, top: 50 };
    }

    return { top: 0 };
  },
});

export default router;

router.beforeEach((to, from, next) => {
  /*console.log("to.fullPath" + JSON.stringify(to.fullPath));
  console.log("to.query" + JSON.stringify(to.query));
  console.log("from.fullPath" + JSON.stringify(from.fullPath));
  console.log("from.query" + JSON.stringify(from.query));*/
  if (Object.keys(from.query).length === 0) {
    console.log("if-block calculates TRUE!!!!");
    return next();
  }
  if (to.query.brand == from.query.brand) {
    console.log("SECOND if-block calculates TRUE!!!!");
    return next();
  }
  //if (Object.entries(from.query).length > 0) {
  /*to.query = {
      ...to.query,
      ...from.query,
    };
    */
  to.query = from.query;
  console.log(to.query);
  //}

  next(to);
});
