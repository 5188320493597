<script>
import Navigation from "@/components/Navigation.vue";
import imgs from "@/components/Images.js";
import { computed } from "@vue/reactivity";

export default {
  name: "ImagesPage",
  mounted() {
    document.title = `Gallery | ${this.$t("title")}`;
  },
  data() {
    return {
      brands: [
        {
          key: 0,
          name: "Butterfly",
        },
        {
          key: 1,
          name: "Stiga",
        },
        {
          key: 2,
          name: "Joola",
        },
        {
          key: 3,
          name: "Gewo",
        },
        {
          key: 4,
          name: "Tibhar",
        },
        {
          key: 5,
          name: "Nittaku",
        },
        {
          key: 6,
          name: "Hanno",
        },
      ],
      images: imgs
    };
  },
  methods: {
    brand() {
      return this.$route.query.brand ? this.$route.query.brand : "all";
    },
  },
  components: {
    "my-nav": Navigation,
  },
};
</script>

<template>
  <my-nav />
  <br /><br /><br /><br /><br />
  <div class="gallery">
    <div class="brand-selctor">
      <!-- style="transform: translateX(500px)"  -->
      <select
        @change="$router.push({ query: { brand: $event.target.value } })"
        name="brand"
        id="brand"
      >
        <option selected value="all">
          {{ $t("gallery.all") }}
        </option>
        <option
          v-for="brand in brands"
          :key="brand.key"
          :value="brand.name.toLowerCase()"
        >
          {{ brand.name }}
        </option>
      </select>
    </div>
    <br /><br />
    <ul class="images">
      <li v-for="img in images" :key="img.id" :id="img.id">
        <router-link
          v-if="img.brand.toLowerCase() == brand() || brand() == 'all'"
          :to="`/${$i18n.locale}/gallery/${img.description}`"
        >
          <img :src="img.file" :alt="img.name" />

          <br /><br />

          <span class="image-title">
            {{ img.name }}
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
@import "../scss/vars.scss";

.gallery {
  .brand-selctor {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 3em;
    line-height: 2;
    background: #5c6664;
    overflow: hidden;
    border-radius: 0.25em;
    text-align: center;

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      outline: 0;
      box-shadow: none;
      border: 0 !important;
      background: #5c6664;
      background-image: none;
      flex: 1;
      padding: 0 0.5em;
      color: #fff;
      cursor: pointer;
      font-size: 1.5em;

      &::-ms-expand {
        display: none;
      }
    }

    &::after {
      content: "\25BC";
      line-height: 3;
      position: absolute;
      top: 0;
      right: 0;
      padding: 0 1em;
      background: #2b2e2e;
      cursor: pointer;
      pointer-events: none;
      transition: 0.6s ease all;
    }

    &:hover::after {
      color: #23b499;
    }
  }

  ul.images {
    li {
      margin-bottom: 100px;

      a {
        width: min-content;
        text-decoration: none;
        list-style: none;

        img {
          width: 35%;
          height: 35%;
        }

        span.image-title {
          color: $main-text-color;
          font-size: 20px;
        }
      }
    }
  }
}
</style>
