export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thorsten's Tabletennisblades"])},
  "nav": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thorsten's Tabletennisblades"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact me"])},
    "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testing"])}
  },
  "contact": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
    "message-type": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message type"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation (Email is not optional)"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment (Email is optional)"])}
    },
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Message"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send message"])}
  },
  "gallery": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Brands"])}
  },
  "image-info": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])}
  },
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BACK"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "mobile": {
    "nav": {
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
    }
  }
}