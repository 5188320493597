<script>
import { defineComponent } from "vue";

export default {
  name: "ContactForm",
  data() {
    return {
      emailrequired: false,
      message_type: "comment",

      guest_name: "",
      guest_email: "",
      message_content: "",

      base: process.env.BASE_URL,
    };
  },
  methods: {
    setEmailRequired() {
      if (this.message_type == "comment") {
        this.emailrequired = false;
        return;
      }

      this.emailrequired = true;
      return;
    },

    hasValue(val) {
      if (!val || val == "") {
        return false;
      }

      return true;
    },
  },
};
</script>

<template>
  <form
    action="https://formsubmit.co/9755ce9b29f3fe4242a231055b77c4b2"
    method="POST"
  >
    <input type="hidden" name="_next" :value="`${base}contact`" />
    <input type="hidden" name="_captcha" value="false" />

    <div class="input-field">
      <input
        :class="{ ' filled-input-field': hasValue(guest_name) }"
        v-model="guest_name"
        type="text"
        name="name"
        required
      />
      <label for="name">
        {{ $t("contact.name") }}
      </label>
    </div>

    <div class="input-field">
      <input
        :class="{ 'filled-input-field': hasValue(guest_email) }"
        v-model="guest_email"
        type="email"
        name="email"
        :required="emailrequired"
      />

      <label for="email">
        {{ $t("contact.email") }}
        {{ emailrequired == true ? "" : " (optional)" }}
      </label>
    </div>
    <br />
    <div class="input-field">
      <select
        name="message_type"
        v-model="message_type"
        @change="setEmailRequired"
      >
        <option selected value="comment">
          {{ $t("contact.message-type.comment") }}
        </option>
        <option value="conversation">
          {{ $t("contact.message-type.conversation") }}
        </option>
      </select>

      <label for="message_type">
        {{ $t("contact.message-type.name") }}
      </label>
    </div>

    <div class="input-field">
      <textarea
        :class="{ 'filled-input-field': hasValue(message_content) }"
        v-model="message_content"
        name="message_content"
        required
      ></textarea>

      <label for="message_content">
        {{ $t("contact.message") }}
      </label>
    </div>
    <br /><br />
    <button type="submit">
      {{ $t("contact.send") }}
    </button>
    <br /><br /><br /><br />
  </form>
</template>

<style lang="scss">
@import "../scss/vars.scss";
form {
  display: flex;
  flex-direction: column;

  .input-field {
    position: relative;
    width: 800px;
    margin-top: 35px;

    input,
    textarea,
    select {
      position: relative;
      width: 100%;
      padding: 20px 10px 10px;
      background: transparent;
      border: 2px solid rgb(94, 94, 94);
      border-radius: 10px;
      outline: none;
      font-size: large;
      letter-spacing: 0.05em;
      z-index: 10;
      transition: 0.4s ease all;
      vertical-align: middle;
    }

    textarea {
      height: 300px;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      cursor: pointer;
      color: white;
      vertical-align: middle;
    }

    label {
      position: absolute;
      left: 0;
      padding: 16px 10px 10px;
      font-size: 1.3em;
      color: white;
      pointer-events: none;
      letter-spacing: 0.05em;
      transition: 0.5s ease all;
      vertical-align: middle;
    }

    i {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: rgb(94, 94, 94);
      border-radius: 4px;
      transition: 0.5s;
      pointer-events: none;
      z-index: 9;
    }

    select ~ label {
      color: black;
      transform: translateX(-10px) translateY(-45px);
      font-size: 1em;
    }

    textarea ~ label {
      padding: 16px 10px 10px;
    }

    .filled-input-field ~ label,
    input:focus ~ label,
    textarea:focus ~ label {
      color: black;
      transform: translateX(-10px) translateY(-45px);
      font-size: 1em;
    }

    .filled-input-field,
    input:focus,
    textarea:focus {
      background-color: rgb(94, 94, 94);
    }
  }

  button {
    border: none;
    outline: none;
    background: rgb(94, 94, 94);
    padding: 11px 25px;
    height: 70px;
    margin-top: 20px;
    border-radius: 10px;
    font-size: xx-large;
    font-weight: 500;
    transition: 0.7s;
    color: $main-text-color;

    &:hover {
      background: rgb(149, 149, 149);
    }
  }
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
