<script>
export default {
  name: "App",
  created() {
    this.$watch(
      () => this.$route.params,
      (newParams, oldParams) => {
        var language = newParams.lang;

        if (localStorage.getItem("locale") == language) return;

        if (language != "en" && language != "de") {
          this.$router.push({
            params: { lang: "de" },
          });

          return;
        }

        localStorage.setItem("locale", language);

        window.location.reload();
      }
    )

    this.checkScreenSize();

    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      var appClassList = document.getElementById("app").classList;
      var device = this.getDeviceByScreenSize();

      if (!appClassList.contains(device)) {
        appClassList.remove("mobile");
        appClassList.remove("tablet");
        appClassList.remove("laptop");

        appClassList.add(device);
      }
    },
    getDeviceByScreenSize() {
      const windowWidth = window.innerWidth;

      if (windowWidth <= 700) {
        return "mobile";
      } else if (windowWidth >= 650 && windowWidth <= 1100) {
        return "tablet";
      }

      return "laptop"
    }
  }
}
</script>

<template>
  <router-view />
</template>

<style lang="scss">
@import "./scss/vars.scss";

body {
  min-height: 100vh;
  background: $background;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-weight: 400;
}
</style>