export default [
  {
    id: 0,
    name: "Butterfly Anatomic 1",
    description: "butterfly_anatomic_1",
    file: require("../assets/butterfly_anatomic.png"),
    brand: "Butterfly",
  },
  {
    id: 1,
    name: "Stiga Larsson 1",
    description: "stiga_larsson_1",
    file: require("../assets/stiga_larsson.jpeg"),
    brand: "Stiga",
  },
  {
    id: 2,
    name: "Stiga Hornet 1",
    description: "stiga_hornet_1",
    file: require("../assets/stiga_hornet.jpeg"),
    brand: "Stiga",
  },
  {
    id: 3,
    name: "Stiga Original 1",
    description: "stiga_original_1",
    file: require("../assets/stiga_original.jpeg"),
    brand: "Stiga",
  },
  {
    id: 4,
    name: "Stiga Börszey 1",
    description: "stiga_börszey_1",
    file: require("../assets/stiga_börszey.jpeg"),
    brand: "Stiga",
  },
  {
    id: 5,
    name: "Butterfly Hüging Defence",
    description: "butterfly_hüging_defence_1",
    file: require("../assets/butterfly_hüging_defence.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 6,
    name: "Stiga Mellis",
    description: "stiga_mellis_0",
    file: require("../assets/stiga_mellis0.jpeg"),
    brand: "Stiga",
  },
  {
    id: 7,
    name: "Stiga Bengtsson 1",
    description: "Stiga_bengtsson_a-serie_anniversary_1985",
    file: require("../assets/stiga_bengtsson_a-s_1985.jpeg"),
    brand: "Stiga",
  },
  {
    id: 8,
    name: "Butterfly Cresail 1",
    description:
      "Butterfly cresail spezialanfertigung für die rumänische nationalmannschaft",
    file: require("../assets/butterfly_cresail_rum_1.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 9,
    name: "butterfly Korpa 1",
    description: "Butterfly_korpa_1",
    file: require("../assets/butterfly_korpa.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 10,
    name: "Butterfly Boll Spirit",
    description: "Butterfly boll spirit 1. Serie",
    file: require("../assets/butterfly_boll_spirit.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 11,
    name: "Butterfly Defence 1. Serie",
    description: "Butterfly defence 1. Serie",
    file: require("../assets/butterfly_defence_1-serie.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 12,
    name: "Stiga Ehrlich 1",
    description: "stiga_ehrlich_1",
    file: require("../assets/stiga_ehrlich.jpeg"),
    brand: "Stiga",
  },
  {
    id: 13,
    name: "Butterfly Jonyer 1",
    description: "butterfly_jonyer_1",
    file: require("../assets/butterfly_jonyer.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 14,
    name: "Stiga Harangozo 1",
    description: "stiga_harangazo_1",
    file: require("../assets/stiga_harangozo.jpeg"),
    brand: "Stiga",
  },
  {
    id: 15,
    name: "Stiga Tova Stipancic 1",
    description: "Stiga_tova_stipancic_1",
    file: require("../assets/stiga_tova_stipancic.jpeg"),
    brand: "Stiga",
  },
  {
    id: 16,
    name: "Butterfly Quetzal 1",
    description: "butterfly_quetzal_1",
    file: require("../assets/butterfly_quetzal.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 17,
    name: "Stiga Bernhardt 1. Serie",
    description: "stiga_bernhardt_1_serie",
    file: require("../assets/stiga_bernhardt_1_serie.jpeg"),
    brand: "Stiga",
  },
  {
    id: 18,
    name: "Stiga Mellis 2",
    description: "stiga_mellis_2",
    file: require("../assets/stiga_mellis_2.jpeg"),
    brand: "Stiga",
  },
  {
    id: 19,
    name: "Stiga Bengtsson Super Carbon",
    description: "stiga_bengtsson_super_carbon_1",
    file: require("../assets/stiga_bengtsson_super_carbon.jpeg"),
    brand: "Stiga",
  },
  {
    id: 20,
    name: "Butterfly Viscaria S T 1. Serie",
    description: "butterfly_viscaria_s_t_1_serie",
    file: require("../assets/butterfly_viscaria_serie.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 21,
    name: "Stiga Alser Anniversary B Serie",
    description: "stiga_alser_anniversary_b_serie",
    file: require("../assets/stiga_alser_anniversary_b_serie.jpeg"),
    brand: "Stiga",
  },
  {
    id: 22,
    name: "Stiga Alser Anniversary A Serie",
    description: "stiga_alser_anniversary_a_serie",
    file: require("../assets/stiga_alser_anniversary_a_serie.jpeg"),
    brand: "Stiga",
  },
  {
    id: 23,
    name: "Stiga Berczik",
    description: "stiga_berczik",
    file: require("../assets/stiga_berczik.jpeg"),
    brand: "Stiga",
  },
  {
    id: 24,
    name: "Stiga Ehrlich 1. Serie",
    description: "stiga_ehrlich_1_serie",
    file: require("../assets/stiga_ehrlich_1_serie1.jpeg"),
    brand: "Stiga",
  },
  {
    id: 25,
    name: "Stiga Tova Stipancic",
    description: "stiga_tova_stipancic2.jpeg",
    file: require("../assets/stiga_tova_stipancic2.jpeg"),
    brand: "Stiga",
  },
  {
    id: 26,
    name: "Stiga Mellis",
    description: "stiga_mellis_56",
    file: require("../assets/stiga_mellis_56.jpeg"),
    brand: "Stiga",
  },
  {
    id: 27,
    name: "Stiga Mellis",
    description: "stiga_mellis3",
    file: require("../assets/stiga_mellis3.jpeg"),
    brand: "Stiga",
  },
  {
    id: 28,
    name: "Stiga Mellis",
    description: "stiga_mellis4",
    file: require("../assets/stiga_mellis4.jpeg"),
    brand: "Stiga",
  },
  {
    id: 29,
    name: "Stiga Mellis",
    description: "stiga_mellis5",
    file: require("../assets/stiga_mellis5.jpeg"),
    brand: "Stiga",
  },
  {
    id: 30,
    name: "Stiga Mellis",
    description: "stiga_mellis6",
    file: require("../assets/stiga_mellis6.jpeg"),
    brand: "Stiga",
  },
  {
    id: 31,
    name: "Stiga Mellis",
    description: "stiga_mellis7",
    file: require("../assets/stiga_mellis7.jpeg"),
    brand: "Stiga",
  },
  {
    id: 32,
    name: "Stiga Johannson",
    description: "stiga_johannson",
    file: require("../assets/stiga_johannson.jpeg"),
    brand: "Stiga",
  },
  {
    id: 33,
    name: "Stiga Ehrlich 1. Serie",
    description: "stiga_ehrlich_1_serie_2",
    file: require("../assets/stiga_ehrlich_1_serie2.jpeg"),
    brand: "Stiga",
  },
  {
    id: 34,
    name: "Hanno Ehrlich",
    description: "Hanno Ehrlich",
    file: require("../assets/hanno_ehrlich.jpeg"),
    brand: "Hanno",
  },
  {
    id: 34,
    name: "Butterfly Kenny Style",
    description: "Butterfly Kenny Style",
    file: require("../assets/butterfly_kenny_style.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 35,
    name: "Stiga Ehrlich",
    description: "Stiga Ehrlich",
    file: require("../assets/stiga_ehrlich2.jpeg"),
    brand: "Stiga",
  },
  {
    id: 36,
    name: "Stiga Alser",
    description: "Stiga Alser",
    file: require("../assets/stiga_alser.jpeg"),
    brand: "Stiga",
  },
  {
    id: 37,
    name: "Stiga Garret Nash",
    description: "Stiga Garret Nash",
    file: require("../assets/stiga_garret_nash.jpeg"),
    brand: "Stiga",
  },
  {
    id: 38,
    name: "Stiga Mellis",
    description: "stiga_mellis8",
    file: require("../assets/stiga_mellis8.jpeg"),
    brand: "Stiga",
  },
  {
    id: 39,
    name: "Stiga Mellis",
    description: "stiga_mellis9",
    file: require("../assets/stiga_mellis9.jpeg"),
    brand: "Stiga",
  },
  {
    id: 40,
    name: "Butterfly Viscaria S T",
    description: "Butterfly Viscaria S T",
    file: require("../assets/butterfly_viscaria_s_t.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 41,
    name: "Stiga Bengtsson Anniversary A Serie",
    description: "Stiga Bengtsson Anniversary A Serie",
    file: require("../assets/stiga_bengtsson_anniversary_a_serie.jpeg"),
    brand: "Stiga",
  },
  {
    id: 42,
    name: "Stiga Harangozo",
    description: "Stiga Harangozo",
    file: require("../assets/stiga_harangozo2.jpeg"),
    brand: "Stiga",
  },
  {
    id: 43,
    name: "Butterfly adolescen",
    description: "Butterfly adolescen",
    file: require("../assets/butterfly_adolescen.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 44,
    name: "Stiga original",
    description: "Stiga original 2",
    file: require("../assets/stiga_original2.jpeg"),
    brand: "Stiga",
  },
  {
    id: 45,
    name: "Stiga hornet",
    description: "Stiga hornet 2",
    file: require("../assets/stiga_hornet2.jpeg"),
    brand: "Stiga",
  },
  {
    id: 46,
    name: "Stiga alser a 1975",
    description: "Stiga alser a 1975",
    file: require("../assets/stiga_alser_1975.jpeg"),
    brand: "Stiga",
  },
  {
    id: 47,
    name: "Stiga alser a 1975",
    description: "Stiga alser a 1975",
    file: require("../assets/stiga_alser_1975.jpeg"),
    brand: "Stiga",
  },
  {
    id: 48,
    name: "Stiga hornet",
    description: "Stiga hornet 3",
    file: require("../assets/stiga_hornet3.jpeg"),
    brand: "Stiga",
  },
  {
    id: 49,
    name: "Stiga hornet",
    description: "Stiga hornet 4",
    file: require("../assets/stiga_hornet4.jpeg"),
    brand: "Stiga",
  },
  {
    id: 50,
    name: "Stiga hornet",
    description: "Stiga hornet5",
    file: require("../assets/stiga_hornet5.jpeg"),
    brand: "Stiga",
  },
  {
    id: 51,
    name: "Stiga ehrlich",
    description: "Stiga ehrlich3",
    file: require("../assets/stiga_ehrlich3.jpeg"),
    brand: "Stiga",
  },
  {
    id: 52,
    name: "Stiga ehrlich",
    description: "Stiga ehrlich4",
    file: require("../assets/stiga_ehrlich4.jpeg"),
    brand: "Stiga",
  },
  {
    id: 53,
    name: "Stiga ehrlich",
    description: "Stiga ehrlich5",
    file: require("../assets/stiga_ehrlich5.jpeg"),
    brand: "Stiga",
  },
  {
    id: 54,
    name: "Stiga ehrlich",
    description: "Stiga ehrlich6",
    file: require("../assets/stiga_ehrlich6.jpeg"),
    brand: "Stiga",
  },
  {
    id: 55,
    name: "Stiga ehrlich",
    description: "Stiga ehrlich7",
    file: require("../assets/stiga_ehrlich7.jpeg"),
    brand: "Stiga",
  },
  {
    id: 56,
    name: "Stiga anniversary 1985",
    description: "Stiga alser anniversary b-serie 1985",
    file: require("../assets/stiga_alser_1985.jpeg"),
    brand: "Stiga",
  },
  {
    id: 57,
    name: "Butterfly Tamca 5000",
    description: "Butterfly Tamca 5000",
    file: require("../assets/butterfly_tamca_5000.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 58,
    name: "Stiga ehrlich expander",
    description: "Stiga ehrlich expander",
    file: require("../assets/stiga_ehrlich_expander.jpeg"),
    brand: "Stiga",
  },
  {
    id: 59,
    name: "Stiga ehrlich",
    description: "Stiga ehrlich8",
    file: require("../assets/stiga_ehrlich8.jpeg"),
    brand: "Stiga",
  },
  {
    id: 60,
    name: "Stiga neidert",
    description: "Stiga neidert",
    file: require("../assets/stiga_neidert.jpeg"),
    brand: "Stiga",
  },
  {
    id: 61,
    name: "Stiga alser",
    description: "Stiga alser",
    file: require("../assets/stiga_alser1.jpeg"),
    brand: "Stiga",
  },
  {
    id: 62,
    name: "Stiga original",
    description: "Stiga original2",
    file: require("../assets/stiga_original2.jpeg"),
    brand: "Stiga",
  },
  {
    id: 63,
    name: "Stiga alser anniversary a-serie 1985",
    description: "Stiga alser anniversary a-serie 1985",
    file: require("../assets/stiga_alser_anniversary_a_serie_1985.jpeg"),
    brand: "Stiga",
  },
  {
    id: 64,
    name: "Stiga alser anniversary b-serie 1985",
    description: "Stiga alser anniversary b-serie 1985",
    file: require("../assets/stiga_alser_anniversary_b_serie_1985.jpeg"),
    brand: "Stiga",
  },
  {
    id: 65,
    name: "Butterfly Stipancic",
    description: "Butterfly Stipancic",
    file: require("../assets/butterfly_stipancic.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 66,
    name: "Butterfly Földy",
    description: "Butterfly Földy",
    file: require("../assets/butterfly_földy.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 67,
    name: "Butterfly Korpa Deluxe",
    description: "Butterfly Korpa Deluxe",
    file: require("../assets/butterfly_korpa_deluxe.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 68,
    name: "Butterfly defence 2. Serie 1980",
    description: "Butterfly defence 2. Serie 1980 1",
    file: require("../assets/butterfly_defence_2serie_1980.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 69,
    name: "Butterfly defence 2. Serie 1980",
    description: "Butterfly defence 2. Serie 1980 2",
    file: require("../assets/butterfly_defence_2serie_1980_2.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 70,
    name: "Stiga Johansson Sarajevo 1973",
    description: "Stiga Johansson Sarajevo 1973",
    file: require("../assets/stiga_johansson_sarajevo_1973.jpeg"),
    brand: "Stiga",
  },
  {
    id: 71,
    name: "stiga johansson anniversary b-serie 1985",
    description: "stiga johansson anniversary b-serie 1985",
    file: require("../assets/stiga_johansson_1985.jpeg"),
    brand: "Stiga",
  },
  {
    id: 72,
    name: "Butterfly klampar",
    description: "Butterfly klampar",
    file: require("../assets/butterfly_klampar.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 73,
    name: "Butterfly Jonyer",
    description: "Butterfly Jonyer 2",
    file: require("../assets/butterfly_jonyer2.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 74,
    name: "Butterfly Secretin",
    description: "Butterfly Secretin",
    file: require("../assets/butterfly_secretin.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 75,
    name: "Butterfly borzsey",
    description: "Butterfly borzsey",
    file: require("../assets/butterfly_borzsey.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 76,
    name: "Butterfly Stipancic 2",
    description: "Butterfly Stipancic 2",
    file: require("../assets/butterfly_stipancic2.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 77,
    name: "Butterfly Stipancic ST",
    description: "Butterfly Stipancic ST",
    file: require("../assets/butterfly_stipancic_st.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 78,
    name: "Butterfly Swedish Style",
    description: "Butterfly Swedish Style",
    file: require("../assets/butterfly_swedish_style.jpeg"),
    brand: "Butterfly",
  },
  {
    id: 79,
    name: "Butterfly Anatomic",
    description: "Butterfly Anatomic",
    file: require("../assets/butterfly_anatomic.jpeg"),
    brand: "Butterfly",
  },
];
