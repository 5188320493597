<script>
export default {
  name: "HomePage",
  mounted() {
    document.title = `Home | ${this.$t("nav.header")}`;
  },
};
</script>

<template>
  <div class="home">
    <div class="main-image">
      <h1 class="header">
        {{ $t("nav.header") }}
      </h1>

      <nav>
        <!-- router-link :to="`/${$i18n.locale}/home`" class="nav-link home">
          {{ $t("nav.home") }}
        </router-link -->

        <router-link :to="`/${$i18n.locale}/gallery`" class="nav-link home">
          {{ $t("nav.gallery") }}
        </router-link>

        <!-- router-link :to="`/${$i18n.locale}/contact`" class="nav-link contact">
          {{ $t("nav.contact") }}
        </router-link -->
      </nav>
    </div>
  </div>
</template>

<style lang="scss">
@import "../scss/vars.scss";

.main-image {
  background: linear-gradient(
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.8)
    ),
    url("@/assets/butterfly_anatomic.png");
  background-size: cover;
  font-size: xx-large;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .header {
    color: $main-text-color;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) translateY(-70px);
  }

  nav {
    list-style: none;
    text-decoration: none;
    display: flex;

    .nav-link {
      color: $main-text-color;
      position: relative;
      transition: 0.5s ease all;
      text-decoration: none;
      border-bottom: 2px solid transparent;
      margin-top: 180px solid transparent;
      font-size: xx-large;

      &.home {
        color: #00afea;
        //transform: translateY(160px) translateX(140px);
      }

      &.gallery {
        transform: translateX(-380px);
      }

      &.contact {
        transform: translateX(400px);
      }

      &:hover {
        color: #00afea;
        border-color: #00afea;
      }
    }
  }
}
</style>
