<script>
import Navigation from "@/components/Navigation.vue";
import images from "@/components/Images.js";

export default {
  name: "Image informations",
  data() {
    return {
      image: {
        id: "ImageId",
        name: "ImageName",
        file: "ImageFile",
        brand: "ImageBrand",
        description: "ImageDescription",
      },
    };
  },
  methods: {
    setImage() {
      var newImage = images
        .filter(
          (image) =>
            image.description ==
            this.$router.currentRoute.value.params.imagename
        )
        .at(0);

      this.image = newImage;
    },
    makeLow(name) {
      return name.replace(" ", "_").toLowerCase();
    },
    async goBack() {
      await this.$router.push(`/${this.$i18n.locale}/gallery#${this.image.id}`);
      //window.location.reload();
    },
  },
  mounted() {
    this.setImage();

    document.title = `${this.image.name} | ${this.$t("title")}`;
  },
  components: {
    "my-nav": Navigation,
  },
};
</script>

<template>
  <my-nav />

  <div class="image-infos">
    <span class="image-header">
      {{ image.name }}
    </span>
    <br />
    <span class="image-brand">
      {{ image.brand }}
    </span>
    <br />
    <span class="image">
      <img class="image" :src="image.file" :alt="`${image.name}'s image`" />
    </span>
    <br />
    <span class="image-description">
      {{ image.description }}
    </span>
    <br /><br /><br />
    <!--
    <router-link :to="`/${$i18n.locale}/gallery`" class="go-back">
      {{ $t("back") }}
    </router-link>
     -->
    <button @click="goBack()" class="go-back">
      {{ $t("back") }}
    </button>

    <br /><br />
  </div>
</template>

<style lang="scss">
@import "../scss/vars.scss";

div.image-infos {
  transform: translateY(120px);
  display: flex;
  flex-direction: column;
  align-items: center;

  span.image-header {
    font-size: 30px;
    color: $main-text-color;
  }

  span.image {
    font-size: 30px;

    img {
      width: 65%;
    }
  }

  span.image-brand {
    color: $main-text-color;
    font-size: 25px;
  }

  span.image-description {
    color: $main-text-color;
    font-size: 20px;
  }

  a.go-back {
    font-size: 25px;
    color: $main-text-color;
    text-decoration: none;
    background: none;
  }
}
</style>
