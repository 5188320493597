<script>
export default {
    name: "Navigation",
    mounted() {
        window.addEventListener("resize", this.resize);
    },
    data() {
        return {
            device: document.getElementById("app").classList.item(0),
            navOpen: false
        }
    },
    methods: {
        resize() {
            this.device = document.getElementById("app").classList.item(0);
        }
    }
};
</script>

<template>
    <div class="navigation">
        <nav>
            <router-link :to="`/${$i18n.locale}/home`" class="nav-link-header">
                <img class="logo" src="../assets/logo.png" alt="logo">
                <span v-if="device == 'laptop' || device == 'tablet'" class="nav-link-text">
                    {{ $t("nav.header") }}
                </span>
            </router-link>

            <div v-if="device == 'mobile' || device == 'tablet'" class="open-nav" @click="navOpen = !navOpen">
                <ion-icon class="icon" name="menu"></ion-icon>
            </div>

            <ul :class="`nav-links ${navOpen ? 'nav-open' : ''}`">

                <li class="nav-link contact" v-if="navOpen || device == 'laptop'">
                    <router-link :to="`/${$i18n.locale}/contact`">
                        <span class="nav-link-text">
                            {{ $t("nav.contact") }}
                        </span>
                    </router-link>
                </li>
                <li class="nav-link gallery" v-if="navOpen || device == 'laptop'">
                    <router-link :to="`/${$i18n.locale}/gallery`">
                        <span class="nav-link-text">
                            {{ $t("nav.gallery") }}
                        </span>
                    </router-link>
                </li>
                <li class="nav-link home" v-if="navOpen || device == 'laptop'">
                    <router-link :to="`/${$i18n.locale}/home`">
                        <span class="nav-link-text">
                            {{ $t("nav.home") }}
                        </span>
                    </router-link>
                </li>
            </ul>
        </nav>
    </div>
</template>

<style lang="scss">
@import "../scss/vars.scss";
@import "../scss/mixins.scss";

.navigation {
    background-color: $navigation-color;
    z-index: 10;
    height: 90px;
    width: 100%;
    position: fixed;
    transition: .5s ease all;

    nav {
        margin-bottom: 100px;
        a.nav-link-header img.logo {
            width: 75px;
            height: 75px;
        }
    }
}

#app.mobile .navigation nav {
    .nav-link-header {
        margin-top: 8px;
        margin-left: 20px;
        float: left;
        width: 75px;
    }

    ul.nav-links {
        list-style: none;
        text-decoration: none;
        display: flex;
        flex-direction: row-reverse;
        transition: .5s ease all;
        transform: translateY(600px);

        &.nav-open {
            transform: translateY(100px);
            background-color: $navigation-color;
            width: 100%;
            height: 900px;
            border-radius: 40px 40px 0px 0px;
            box-shadow: 10px -10px 10px $second-navigation-color;
        }

        li.nav-link {
            margin-right: 13%;

            a {
                writing-mode: vertical-lr;
                text-orientation: upright;
                text-decoration: none;
                display: flex;
                flex-direction: row-reverse;

                &.router-link-active span {
                    color: $active-link-color;

                    &:hover {
                        color: $active-link-color;
                    }
                }

                span {
                    transform: translateY(20px);
                    font-weight: 500;
                    font-size: 25px;
                    color: $main-text-color;
                    transition: .5s ease all;
                    text-transform: uppercase;
                }

                &:hover span {
                    color: $hovered-link-color;
                }
            }
        }
    }

    div.open-nav {
        width: 48px;
        transform: translateY(20px);
        margin-right: 20px;
        float: right;

        .icon {
            font-size: 300%;
        }
    }
}

// #app.tablet .navigation nav {}

#app.laptop .navigation nav {
    transition: .5s ease all;
    width: 98%;

    a.nav-link-header {
        display: flex;
        flex-direction: row;
        width: 560px;
        text-decoration: none;
        margin-left: 13px;
        margin-top: 13px;
        transform: translateY(-5px);

        span {
            font-weight: 500;
            font-size: 35px;
            transition: .5s ease all;
            text-transform: uppercase;
            border-bottom: 1px solid transparent;
            color: $main-text-color;
        }

        &:hover span {
            color: $main-text-color;
            border-color: transparent;
        }
    }


    ul.nav-links {
        list-style: none;
        text-decoration: none;
        display: flex;
        flex-direction: row-reverse;
        gap: 1.5%;
        transform: translateY(-30px);

        li.nav-link a {
            display: flex;
            text-decoration: none;
            transform: translateY(-30px);

            &.router-link-active span {
                color: $active-link-color;

                &:hover {
                    color: $active-link-color;
                    border-color: transparent;
                }
            }

            span {
                font-weight: 500;
                font-size: 25px;
                color: $main-text-color;
                transition: .5s ease all;
                text-transform: uppercase;
                border-bottom: 2px solid transparent;
            }

            &:hover span {
                color: $hovered-link-color;
                border-color: $hovered-link-color;
            }
        }
    }
}
</style>