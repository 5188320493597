export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thorsten's Tischtennishölzer"])},
  "nav": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thorsten's Tischtennishölzer"])},
    "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galerie"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktiere mich"])},
    "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testen"])}
  },
  "contact": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreibe uns eine Nachricht"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Email"])},
    "message-type": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht Art"])},
      "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konversation (Email ist nicht optional)"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar (Email ist optional)"])}
    },
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Nachricht"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht senden"])}
  },
  "gallery": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Marken"])}
  },
  "image-info": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marke"])}
  },
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZURÜCK"])},
  "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
  "mobile": {
    "nav": {
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
    }
  }
}