<script>
import Navigation from "@/components/Navigation.vue";
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "ContactPage",
  mounted() {
    document.title = `Contact us | ${this.$t("title")}`;
  },
  components: {
    "my-nav": Navigation,
    "contact-form": ContactForm,
  },
};
</script>

<template>
  <my-nav />

  <div class="contact center">
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <h1>{{ $t("contact.contact") }}</h1>

    <contact-form />
  </div>
</template>

<style lang="scss">
@import "../scss/vars.scss";

.contact {
  color: $main-text-color;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
